import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import moment from "moment";
import { companies } from "./utils/companies";
import { LOGGED_IN_PERSIST_KEY, LOGIN_URL } from "./utils/variables";

const Reporting = ({ ...props }) => {
  const { company, token, importOptions } = props;

  const [lowerbound_date, setLowerboundDate] = useState(
    moment().startOf("month").toDate()
  );
  const [upperbound_date, setUpperboundDate] = useState(moment().toDate());

  const [leadId, setLeadId] = useState<string>("");
  const [leadIdLoading, setLeadIdLoading] = useState(false);
  const [leadActivityId, setLeadActivityId] = useState<string>("");
  const [leadReportLoading, setLeadReportLoading] = useState(false);
  const [leadReport, setLeadReport] = useState<string>("");
  const [leadImport, setLeadImport] = useState<string>("");
  const [leadImportLoading, setLeadImportLoading] = useState(false);
  const [import_history_id, setImportHistoryId] = useState<string>("");
  const [leadValueData, setLeadValueData] = useState<any>("");

  const [multiplierLoading, setMultiplierLoading] = useState(false);
  const [multiplierReport, setMultiplierReport] = useState<string>("");

  const onChangeLowerBound = (lowerbound_date: Date) =>
    setLowerboundDate(lowerbound_date);
  const onChangeUpperBound = (upperbound_date: Date) =>
    setUpperboundDate(upperbound_date);

  const generateCallResultReport = async (event: any) => {
    event?.preventDefault();
    if (!company) {
      return;
    }
    setLeadReportLoading(true);
    setLeadReport("");
    // login
    console.log("token: ", token);
    const response = await axios.get(
      `${
        companies[company].baseUrl
      }/upload/downloadLeadReport?organization_id=${
        companies[company].value
      }&lowerbound_date=${moment(
        lowerbound_date
      ).format()}&upperbound_date=${moment(upperbound_date).format()}`,
      {
        headers: { Authorization: companies[company].token },
      }
    );
    console.log("response: ", response);
    if (response.data.error) {
      setLeadReportLoading(false);
      toast(response.data.error);
      return;
    }
    if (!response.data.report_url) {
      setLeadReportLoading(false);
      toast("Could not get report.");
    }
    setLeadReportLoading(false);
    setLeadReport(response.data.report_url);
  };

  const generateMultiplierReport = async (event: any) => {
    event?.preventDefault();
    if (!company) {
      return;
    }
    setMultiplierLoading(true);
    setMultiplierReport("");
    // login
    console.log("token: ", token);
    const response = await axios.get(
      `${companies[company].baseUrl}/upload/downloadMultiplierReport?organization_id=${companies[company].value}`,
      {
        headers: { Authorization: companies[company].token },
      }
    );
    console.log("response: ", response);
    if (response.data.error) {
      setMultiplierLoading(false);
      toast(response.data.error);
      return;
    }
    if (!response.data.report_url) {
      setMultiplierLoading(false);
      toast("Could not get report.");
      return;
    } else {
      setMultiplierReport(response.data.report_url);
      setMultiplierLoading(false);
    }
  };

  const generateLeadImportFile = async (event: any) => {
    event?.preventDefault();
    setLeadImportLoading(true);
    setLeadImport("");
    if (!import_history_id) {
      toast("Please select import");
    }
    // login
    console.log("token: ", token);
    const response = await axios.get(
      `${companies[company].baseUrl}/upload/getLeadImport?import_history_id=${import_history_id}`,
      {
        headers: { Authorization: companies[company].token },
      }
    );
    console.log("response: ", response);
    if (response.data.error) {
      setLeadImportLoading(false);
      toast(response.data.error);
      return;
    }
    if (!response.data.report_url) {
      setLeadReportLoading(false);
      toast("Could not get report.");
      return;
    } else {
      setLeadImport(response.data.report_url);
      setLeadImportLoading(false);
    }
  };

  const generateLeadValueReport = async (event: any) => {
    event?.preventDefault();
    setLeadIdLoading(true);
    setLeadValueData(null);
    if (!leadId) {
      toast("Please input a lead id");
    }
    // login
    console.log("token: ", token);
    const response = await axios.get(
      `${companies[company].baseUrl}/upload/lvAuditReport?lead_id=${leadId}${
        leadActivityId ? `&lead_activity_id=${leadActivityId}` : ``
      }`,
      {
        headers: { Authorization: companies[company].token },
      }
    );
    console.log("response: ", response);
    if (response.data.error) {
      setLeadIdLoading(false);
      toast(response.data.error);
      return;
    }
    if (!response.data.lv_report) {
      setLeadIdLoading(false);
      toast("Could not get report.");
      return;
    } else {
      setLeadValueData(response.data.lv_report);
      setLeadIdLoading(false);
    }
  };

  return (
    <>
      <h3>Lead Activity Report</h3>
      <p>Lowerbound date</p>
      <DatePicker
        selected={lowerbound_date}
        onChange={(date) => onChangeLowerBound(date as any)}
        // onChange={(date) => console.log("data: ", data)}
      />
      <p>Upperbound date</p>
      <DatePicker
        selected={upperbound_date}
        onChange={(date) => onChangeUpperBound(date as any)}
      />
      {leadReportLoading ? (
        <p>loading...</p>
      ) : (
        <GenerateButton
          style={{ marginTop: 25 }}
          onClick={generateCallResultReport}
          disabled={!company}
        >
          Generate Call Result Report
        </GenerateButton>
      )}
      {!!leadReport && (
        <a href={leadReport} target="_blank">
          <p>Download Lead Report</p>
        </a>
      )}
      <hr />
      <h3>Algo Multiplier Report</h3>
      {multiplierLoading ? (
        <p>loading...</p>
      ) : (
        <GenerateButton onClick={generateMultiplierReport} disabled={!company}>
          {" "}
          Generate Multiplier Report
        </GenerateButton>
      )}
      {!!multiplierReport && (
        <a href={multiplierReport} target="_blank">
          <p>Download Multiplier Report</p>
        </a>
      )}
      <hr />
      <h3>Lead Import Report</h3>
      <StyledSelect
        onChange={(data: any) => setImportHistoryId(data.value)}
        // onChange={(data: any) => console.log("data: ", data)}
        placeholder="Lead Import"
        options={importOptions}
        // value={import_history_id}
      />
      {leadImportLoading ? (
        <p>loading...</p>
      ) : (
        <GenerateButton onClick={generateLeadImportFile} disabled={!company}>
          {" "}
          Generate Lead Import Report
        </GenerateButton>
      )}
      {!!leadImport && (
        <a href={leadImport} target="_blank">
          <p>Download Lead Import Report</p>
        </a>
      )}
      <hr />
      <h3>Lead Value Report</h3>
      <p>Lead ID (required)</p>
      <StyledInput
        onChange={(data: any) => setLeadId(data.target.value)}
        value={leadId}
        placeholder="Unique id of lead"
      />
      <p>Lead Activity ID (optional)</p>
      <StyledInput
        onChange={(data: any) => setLeadActivityId(data.target.value)}
        value={leadActivityId}
        placeholder="Unique id of lead activity"
      />
      {leadIdLoading ? (
        <p>loading...</p>
      ) : (
        <GenerateButton onClick={generateLeadValueReport} disabled={!company}>
          {" "}
          Generate Lead Value Report
        </GenerateButton>
      )}
      {!!leadValueData && (
        <GridWrapper>
          <MainGrid>
            <TableItem>
              <TitleText>Lead ID</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>{leadValueData.lead_id}</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>AOV</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>{leadValueData.aov}</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>channel</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>{leadValueData.channel}</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>Industry</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>{leadValueData.industry}</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>Lead Source</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>{leadValueData.lead_source}</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>Is Active?</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>{leadValueData.is_active}</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>Next Implied Action</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>{leadValueData.next_implied_action}</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>Last Disposition Action</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>{leadValueData.last_disposition_action}</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>Last Disposition Callback Type</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>
                {leadValueData.last_disposition_callback_type}
              </TitleText>
            </TableItem>
            <TableItem>
              <TitleText>Last Disposition Person Spoke To</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>
                {leadValueData.last_disposition_person_spoke_to}
              </TitleText>
            </TableItem>
            <TableItem>
              <TitleText>Last Disposition Type</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>{leadValueData.last_disposition_type}</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>LTV Multiplier</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>{leadValueData.ltv_multiplier}</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>Final LV</TitleText>
            </TableItem>
            <TableItem>
              <TitleText>{leadValueData.final_lv}</TitleText>
            </TableItem>
          </MainGrid>
          <h1>Hinge Point Calculation Details</h1>
          {leadValueData.hp_calculations.map((calc: any) => (
            <MainGrid key={`${leadValueData.lead_id}${calc.hinge_point}`}>
              <TableItem>
                <TitleText>Hinge Point</TitleText>
              </TableItem>
              <TableItem>
                <TitleText>{calc.hinge_point}</TitleText>
              </TableItem>
              <TableItem>
                <ItemText>Hinge Point Rate</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.hinge_point_rate}</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>Lead Field Industry Rate</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.lead_field_industry_rate}</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>Lead Field Lead Source Rate</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.lead_field_lead_source_rate}</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>Lead Field Channel Rate</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.lead_field_channel_rate}</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>Lead Field Custom Rate</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.lead_field_custom_rate}</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>Lead Field Rate</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.lead_field_rate}</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>DM Multiplier Rate</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.dm_multiplier_rate}</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>BAE Multiplier</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.bae_multiplier}</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>Profile Value</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.profile_value}</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>Call Multiplier</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.call_multiplier}</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>SMS and Email Multiplier</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.sms_email_multiplier}</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>Lead Value Subtotal</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.lead_value_subtotal}</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>Industry LF Rate Calc Breakdown</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.ind_calc_string}</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>Lead Source LF Rate Calc Breakdown</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.ls_calc_string}</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>Channel LF Rate Calc Breakdown</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.channel_calc_string}</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>Custom Field LF Rate Calc Breakdown</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.custom_calc_string}</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>Individual Channel Multiplier</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.channel_string}</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>Individual Industry Multiplier</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.ind_string}</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>Individual Lead Source Multiplier</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.ls_string}</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>Individual DM Multiplier</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.dm_multiplier_string}</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>Disposition Multiplier Calc Breakdown</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.dm_calc_string}</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>Profile Value Calc Breakdown</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.profile_value_string}</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>Lead Value at HP breakdown</ItemText>
              </TableItem>
              <TableItem>
                <ItemText>{calc.lead_value_subtotal_string}</ItemText>
              </TableItem>
              <TableItemNoBorder></TableItemNoBorder>
              <TableItemNoBorder></TableItemNoBorder>
            </MainGrid>
          ))}
        </GridWrapper>
      )}
    </>
  );
};

const StyledSelect = styled(Select)`
  width: 500px;
  margin-bottom: 15px;
`;

const Button = styled.button`
  height: 35px;
  width: 200px;
`;

const TableItem = styled.div`
  height: 25px;
  padding: 5px;
  display: flex;
  align-items: center;
  border: 0.5px solid #000;
`;

const GridWrapper = styled.div`
  margin-top: 100px;
  margin-bottom: 50px;
`;

const TitleText = styled.h3`
  font-size: 18px;
  font-weight: 600;
`;

const ItemText = styled.p`
  font-size: 14px;
  font-weight: 400;
`;

const MainGrid = styled.div`
  display: grid;
  grid-template-columns: 400px 400px;
`;

const TableItemNoBorder = styled(TableItem)`
  border: 0 solid #fff;
`;

const GenerateButton = styled(Button)``;

const StyledInput = styled.input`
  width: 500px;
  margin-bottom: 15px;
`;

export default Reporting;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Spinner from "react-spinkit";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import { companies } from "./utils/companies";
import { countries } from "./utils/variables";

const NewNumber = ({ ...props }) => {
  const { company } = props;

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedRep, setSelectedRep] = useState("");
  // used for Select dropdown value
  const [countryValue, setCountryValue] = useState({
    value: "US",
    label: "United States",
  });
  const [repValue, setRepValue] = useState({ value: "", label: "" });

  const [repList, setRepList] = useState([]);
  const [beta, setBeta] = useState(false);

  const [confirmationMsg, setConfirmationMsg] = useState<string>("");
  const [complianceCheck, setComplianceCheck] = useState<{
    a2p: boolean;
    shst: boolean;
  }>({
    a2p: false,
    shst: false,
  });
  const [loadingNumber, setLoadingNumber] = useState<boolean>(false);

  const handleNewNumber = async (data: { id: string; country: string }) => {
    console.log(
      `purchase new number for ${data.id} in country: ${data.country}`
    );
    let response;
    try {
      response = await axios
        .post(
          `${
            !beta ? companies[company]?.baseUrl : "https://api.beta.opsiq.ai"
          }/twilio/purchaseNewPhone`,
          {
            user_id: selectedRep,
            country: selectedCountry,
          }
        )
        .then((res: any) => {
          const newNumber = res?.data?.new_phone;
          if (!!newNumber) {
            toast(`User's number has been updated!`);
            setConfirmationMsg(
              `${repValue?.label} number has been updated to: ${newNumber}`
            );
            setComplianceCheck({
              a2p: res?.data?.a2p_registration,
              shst: res?.data?.shaken_stir_registration,
            });
          }
          setLoadingNumber(false);
        });
    } catch (e) {
      console.log("handleNewNumber error: ", e);
      toast("Error creating new number.");
      setConfirmationMsg(`Error creating new number for ${repValue?.label}`);
      setLoadingNumber(false);
      return;
    }
  };

  const fetchReps = async () => {
    const response = await axios
      .get(
        `${
          !beta ? companies[company]?.baseUrl : "https://api.beta.opsiq.ai"
        }/extension/reps?org_id=${companies[company]?.value}`,
        {
          headers: { Authorization: companies[company]?.token },
        }
      )
      .catch((e: any) => {
        console.log(e, "RESETTING REP LIST");
        setRepList([]);
      });

    if (response?.status === 200 && response?.data) {
      const formattedRepList = response.data?.map(
        (rep: { id: string; email: string }) => ({
          label: rep.email,
          value: rep.id,
        })
      );
      setRepList(formattedRepList);
    }
  };

  useEffect(() => {
    if (!!company) {
      fetchReps();
    }
    // reset field values on org/beta change
    setRepValue({ label: "", value: "" });
    setCountryValue({ label: "", value: "" });
    setSelectedRep("");
    setSelectedCountry("");
  }, [company, beta]);

  return (
    <>
      <h3>
        Select a country:
        <span style={{ color: "red" }}> *</span>
      </h3>
      <StyledSelect
        placeholder="Country (required)"
        value={countryValue}
        options={countries}
        onChange={(c: any) => {
          setSelectedCountry(c.value);
          setCountryValue(c);
        }}
      />
      <h3>
        Select a rep:
        <span style={{ color: "red" }}> *</span>
      </h3>
      <StyledSelect
        placeholder="Rep (required)"
        value={repValue}
        options={repList}
        onChange={(r: any) => {
          setSelectedRep(r.value);
          setRepValue(r);
        }}
      />

      <SubmitContainer>
        <BetaLabel>
          Beta:
          <input
            type="checkbox"
            checked={beta}
            onChange={() => {
              setBeta(!beta);
              setSelectedCountry("");
              setSelectedRep("");
              setRepValue({ label: "", value: "" });
              setCountryValue({ label: "", value: "" });
              console.log("check", selectedRep, selectedCountry);
            }}
            style={{ width: "16px", height: "16px", cursor: "pointer" }}
          />
        </BetaLabel>
        <Button
          disabled={!selectedCountry || !selectedRep || loadingNumber}
          onClick={() => {
            handleNewNumber({ id: selectedRep, country: selectedCountry });
            setLoadingNumber(true);
          }}
        >
          Create Number
        </Button>
        {loadingNumber && (
          <Spinner name="line-scale-pulse-out" color="#3e65ff" fadeIn="none" />
        )}
      </SubmitContainer>

      <Instructions>
        Instructions:
        <li>
          Select an organization and country associated with the new number.
        </li>
        <li>
          Select a rep and check whether or not this will be applied to a beta
          organization.
        </li>
        <li>Click "Create Number" and await the confirmation toast.</li>
      </Instructions>

      <p>{confirmationMsg}</p>
      {!!confirmationMsg.length && (
        <>
          <p>
            A2P 10DLC Registration:
            <ComplianceConfirmation check={complianceCheck?.a2p}>
              {complianceCheck?.a2p ? " Success" : " Fail"}
            </ComplianceConfirmation>
          </p>
          <p>
            Shaken/Stir Registration:
            <ComplianceConfirmation check={complianceCheck?.shst}>
              {complianceCheck?.shst ? " Success" : " Fail"}
            </ComplianceConfirmation>
          </p>
        </>
      )}
    </>
  );
};

const StyledSelect = styled(Select)`
  width: 350px;
  margin-bottom: 15px;
`;

const Button = styled.button`
  height: 35px;
  width: 200px;
  cursor: pointer;
`;

const SubmitContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 24px;
`;

const BetaLabel = styled.label`
  display: flex;
  align-items: center;
`;

const Instructions = styled.ul`
  color: #3e4d5c;
`;

interface ComplianceConfirmationProps {
  check: boolean;
}

const ComplianceConfirmation = styled.span<ComplianceConfirmationProps>`
  color: ${(props) => (props.check ? "green" : "red")};
  font-weight: bold;
`;

export default NewNumber;

interface ICompany {
  label: string;
  value: string;
  baseUrl: string;
  token: string;
}
interface ICompanies {
  [key: string]: ICompany;
}

const companies: ICompanies = {
  SureFire: {
    label: "SureFire",
    value: "1236a64a-c7cc-48e5-9282-aa049373e1b9",
    baseUrl: "https://api.opsiq.ai",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImVtYWlsIjoiYWRtaW5Ab3BzaXEuYWkiLCJpZCI6Ijc2YTEyNzE0LTlmNzItNGFkYy1iMzYzLWY1M2E5Mjg0MDI4YyIsIm9yZ2FuaXphdGlvbl9pZCI6ImFlMGI3YmRhLWFkNmUtNGVlOC1hMmM5LTkwZGMyOWQ3NmY4NiIsInJvbGUiOiJTVVBFUkFETUlOIn0sImlhdCI6MTYwOTIwOTEzM30.O3riyMxHuRfwCooTCU42VqXB_J_61he15_EHejomZtc",
  },
  OllyOlly: {
    label: "Olly Olly",
    value: "1e62cb53-99e5-4026-a038-81e2c2ec5e08",
    baseUrl: "https://api.opsiq.ai",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImVtYWlsIjoiYWRtaW5Ab3BzaXEuYWkiLCJpZCI6Ijc2YTEyNzE0LTlmNzItNGFkYy1iMzYzLWY1M2E5Mjg0MDI4YyIsIm9yZ2FuaXphdGlvbl9pZCI6ImFlMGI3YmRhLWFkNmUtNGVlOC1hMmM5LTkwZGMyOWQ3NmY4NiIsInJvbGUiOiJTVVBFUkFETUlOIn0sImlhdCI6MTYwOTIwOTEzM30.O3riyMxHuRfwCooTCU42VqXB_J_61he15_EHejomZtc",
  },
  BrightWheel: {
    label: "BrightWheel",
    value: "3630155a-8136-42bb-8859-2ff7504189ca",
    baseUrl: "https://api.opsiq.ai",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImVtYWlsIjoiYWRtaW5Ab3BzaXEuYWkiLCJpZCI6Ijc2YTEyNzE0LTlmNzItNGFkYy1iMzYzLWY1M2E5Mjg0MDI4YyIsIm9yZ2FuaXphdGlvbl9pZCI6ImFlMGI3YmRhLWFkNmUtNGVlOC1hMmM5LTkwZGMyOWQ3NmY4NiIsInJvbGUiOiJTVVBFUkFETUlOIn0sImlhdCI6MTYwOTIwOTEzM30.O3riyMxHuRfwCooTCU42VqXB_J_61he15_EHejomZtc",
  },
  "RealWork Labs": {
    label: "RealWork Labs",
    value: "d7c7cd53-fab1-4a9b-96ca-1f9e24d3932c",
    baseUrl: "https://api.opsiq.ai",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImVtYWlsIjoiYWRtaW5Ab3BzaXEuYWkiLCJpZCI6Ijc2YTEyNzE0LTlmNzItNGFkYy1iMzYzLWY1M2E5Mjg0MDI4YyIsIm9yZ2FuaXphdGlvbl9pZCI6ImFlMGI3YmRhLWFkNmUtNGVlOC1hMmM5LTkwZGMyOWQ3NmY4NiIsInJvbGUiOiJTVVBFUkFETUlOIn0sImlhdCI6MTYwOTIwOTEzM30.O3riyMxHuRfwCooTCU42VqXB_J_61he15_EHejomZtc",
  },
  "Vital Interaction": {
    label: "Vital Interaction",
    value: "1001393e-1817-4d89-901a-4fa74e44a848",
    baseUrl: "https://api.opsiq.ai",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImVtYWlsIjoiYWRtaW5Ab3BzaXEuYWkiLCJpZCI6Ijc2YTEyNzE0LTlmNzItNGFkYy1iMzYzLWY1M2E5Mjg0MDI4YyIsIm9yZ2FuaXphdGlvbl9pZCI6ImFlMGI3YmRhLWFkNmUtNGVlOC1hMmM5LTkwZGMyOWQ3NmY4NiIsInJvbGUiOiJTVVBFUkFETUlOIn0sImlhdCI6MTYwOTIwOTEzM30.O3riyMxHuRfwCooTCU42VqXB_J_61he15_EHejomZtc",
  },
  Meallogix: {
    label: "Meallogix",
    value: "e92f00e3-7533-4c8e-8869-e29a11b81a39",
    baseUrl: "https://api.opsiq.ai",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImVtYWlsIjoiYWRtaW5Ab3BzaXEuYWkiLCJpZCI6Ijc2YTEyNzE0LTlmNzItNGFkYy1iMzYzLWY1M2E5Mjg0MDI4YyIsIm9yZ2FuaXphdGlvbl9pZCI6ImFlMGI3YmRhLWFkNmUtNGVlOC1hMmM5LTkwZGMyOWQ3NmY4NiIsInJvbGUiOiJTVVBFUkFETUlOIn0sImlhdCI6MTYwOTIwOTEzM30.O3riyMxHuRfwCooTCU42VqXB_J_61he15_EHejomZtc",
  },
  Clear: {
    label: "Clear",
    value: "a649e5a9-5ff7-42df-8c0c-b06120094fc9",
    baseUrl: "https://api.opsiq.ai",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImVtYWlsIjoiYWRtaW5Ab3BzaXEuYWkiLCJpZCI6Ijc2YTEyNzE0LTlmNzItNGFkYy1iMzYzLWY1M2E5Mjg0MDI4YyIsIm9yZ2FuaXphdGlvbl9pZCI6ImFlMGI3YmRhLWFkNmUtNGVlOC1hMmM5LTkwZGMyOWQ3NmY4NiIsInJvbGUiOiJTVVBFUkFETUlOIn0sImlhdCI6MTYwOTIwOTEzM30.O3riyMxHuRfwCooTCU42VqXB_J_61he15_EHejomZtc",
  },
  HomeLytics: {
    label: "HomeLytics",
    value: "f3226ce3-25ca-41f6-a8a6-7a2329fede03",
    baseUrl: "https://api.staging.opsiq.ai",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImVtYWlsIjoiYWRtaW5Ab3BzaXEuYWkiLCJpZCI6Ijc2YTEyNzE0LTlmNzItNGFkYy1iMzYzLWY1M2E5Mjg0MDI4YyIsIm9yZ2FuaXphdGlvbl9pZCI6ImFlMGI3YmRhLWFkNmUtNGVlOC1hMmM5LTkwZGMyOWQ3NmY4NiIsInJvbGUiOiJTVVBFUkFETUlOIn0sImlhdCI6MTYwOTIwOTEzM30.O3riyMxHuRfwCooTCU42VqXB_J_61he15_EHejomZtc",
  },
  dev: {
    label: "Dev",
    value: "fb969838-92b5-4acf-ab03-38ec19a71140",
    baseUrl: "https://api.staging.opsiq.ai",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImVtYWlsIjoiYWRtaW5Ab3BzaXEuY29tIiwiaWQiOiI0NDM4NGIzMS0xODU0LTRmM2YtOTFkNC04YjYyODhiZGVhMmEiLCJvcmdhbml6YXRpb25faWQiOiIyNzBmNzBmOC00M2I0LTRhM2MtODhiMC02Mjg1MmIwNWNkYmMiLCJyb2xlIjoiU1VQRVJBRE1JTiJ9LCJpYXQiOjE2MDkyMDYzNjV9._QJiheVZ-5cZf34aoV_YBblmmdvRkAiPBS00keFATYo",
  },
  RapptrTest: {
    label: "Rapptr Test",
    value: "91f1b9c2-009b-48f9-93e5-b044788c1aa5",
    baseUrl: "https://api.opsiq.ai",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImVtYWlsIjoiYWRtaW5Ab3BzaXEuYWkiLCJpZCI6Ijc2YTEyNzE0LTlmNzItNGFkYy1iMzYzLWY1M2E5Mjg0MDI4YyIsIm9yZ2FuaXphdGlvbl9pZCI6ImFlMGI3YmRhLWFkNmUtNGVlOC1hMmM5LTkwZGMyOWQ3NmY4NiIsInJvbGUiOiJTVVBFUkFETUlOIn0sImlhdCI6MTYwOTIwOTEzM30.O3riyMxHuRfwCooTCU42VqXB_J_61he15_EHejomZtc",
  },
  MXTestOrg: {
    label: "MX test Org",
    value: "194a376a-e526-11ed-b5ea-0242ac120002",
    baseUrl: "https://api.beta.opsiq.ai",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImVtYWlsIjoiYWRtaW5Ab3BzaXEuYWkiLCJpZCI6Ijc2YTEyNzE0LTlmNzItNGFkYy1iMzYzLWY1M2E5Mjg0MDI4YyIsIm9yZ2FuaXphdGlvbl9pZCI6ImFlMGI3YmRhLWFkNmUtNGVlOC1hMmM5LTkwZGMyOWQ3NmY4NiIsInJvbGUiOiJTVVBFUkFETUlOIn0sImlhdCI6MTYwOTIwOTEzM30.O3riyMxHuRfwCooTCU42VqXB_J_61he15_EHejomZtc",
  },
};

const companyOptionsRaw = [
  {
    label: "SureFire",
    value: "SureFire",
    active: true,
    allowReport: false,
  },
  {
    label: "Olly Olly",
    value: "OllyOlly",
    active: true,
    allowReport: false,
  },
  {
    label: "RealWork Labs",
    value: "RealWork Labs",
    active: true,
    allowReport: true,
  },
  {
    label: "BrightWheel",
    value: "BrightWheel",
    active: true,
    allowReport: true,
  },
  {
    label: "Vital Interaction",
    value: "Vital Interaction",
    active: true,
    allowReport: true,
  },
  {
    label: "Meallogix (not live yet)",
    value: "Meallogix",
    active: false,
    allowReport: true,
  },
  {
    label: "Clear",
    value: "Clear",
    active: true,
    allowReport: true,
  },
  {
    label: "HomeLytics",
    value: "HomeLytics",
    active: false,
    allowReport: true,
  },
  {
    label: "Dev",
    value: "dev",
    active: true,
    allowReport: true,
  },
  {
    label: "Rapptr Test",
    value: "RapptrTest",
    active: true,
    allowReport: false,
  },
  {
    label: "MX test Org",
    value: "MXTestOrg",
    active: true,
    allowReport: false,
  },
];

export { companies, companyOptionsRaw };

import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import moment from "moment";

import { companies, companyOptionsRaw } from "./utils/companies";
import { LOGGED_IN_PERSIST_KEY, LOGIN_URL } from "./utils/variables";
import Reporting from "./Reporting";
import NewNumber from "./NewNumber";

interface OptionItem {
  value: string;
  label: string;
}

function App() {
  const [token, setToken] = useState<string>("");
  const [loginError, setLoginError] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [company, setCompany] = useState("");
  // used for Select dropdown value
  const [companyValue, setCompanyValue] = useState({
    value: "dev",
    label: "Dev",
  });

  const [importOptions, setImportOptions] = useState<OptionItem[]>([]);
  const [password, setPassword] = useState<string>("");

  const [selectedTab, setSelectedTab] = useState<string>("reporting");

  const companyOptions = companyOptionsRaw
    .filter((o) => o.active)
    .map(({ active, ...c }) => ({ ...c }));

  useEffect(() => {
    if (token) {
      localStorage.setItem(LOGGED_IN_PERSIST_KEY, `${token}`);
    } else {
      // localStorage.removeItem(LOGGED_IN_PERSIST_KEY);
    }
  }, [token]);

  useEffect(() => {
    setLoginError("");
    const token = localStorage.getItem(LOGGED_IN_PERSIST_KEY);
    console.log("token from persist: ", token);
    if (token) {
      setToken(token);
    }
  }, []);

  useEffect(() => {
    if (token && company) {
      const fetchOptions = async () => {
        const response = await axios.get(
          `${
            companies[company as any]?.baseUrl
          }/upload/getImportHistory?organization_id=${
            companies[company]?.value
          }`,
          {
            headers: { Authorization: companies[company]?.token },
          }
        );
        if (response.data) {
          setImportOptions(response.data);
        }
      };
      fetchOptions();
    }
  }, [token, company]);

  const logOut = () => {
    setToken("");
    setCompany("");
    localStorage.removeItem(LOGGED_IN_PERSIST_KEY);
  };
  const onChangeEmail = (email: string) => setEmail(email);
  const onChangePassword = (password: string) => setPassword(password);
  const onSubmitLogin = async (event: any) => {
    setLoginError("");
    event?.preventDefault();
    let response;
    // login
    try {
      response = await axios.post(LOGIN_URL, { email, password });
    } catch (e) {
      console.log("loginError: ", e);
      if (e) {
        // setLoginError(e);
        setLoginError("Invalid login");
      } else {
        setLoginError("Invalid login");
      }
      return;
    }
    console.log("response: ", response);
    if (response?.data?.error) {
      toast(response?.data?.error);
      setLoginError(response?.data?.error);
      return;
    }
    if (!response?.data?.token) {
      setLoginError("Invalid Login");
      toast("Invalid Login");
      return;
    } else {
      setToken(response?.data?.token);
      setLoginError("");
    }
  };

  if (!token) {
    return (
      <Div>
        <ToastContainer />
        <p>Admin Login</p>
        <Form onSubmit={onSubmitLogin}>
          <Input
            placeholder="Username"
            type="email"
            onChange={(e) => onChangeEmail(e.target.value)}
          />
          <Input
            placeholder="Password"
            type="password"
            onChange={(e) => onChangePassword(e.target.value)}
          />
          <Button>Submit</Button>
          {!!loginError && <ErrorText>{loginError}</ErrorText>}
        </Form>
      </Div>
    );
  }

  return (
    <>
      <Header>
        <ToastContainer />
        <p>Welcome to OpsIQ Reporting</p>

        <ControlContainer>
          <LogoutButton onClick={logOut}>Logout</LogoutButton>

          <StyledSelect
            placeholder="Environment (required)"
            options={companyOptions}
            value={companyValue}
            onChange={(data: any) => {
              setCompany(data.value);
              setCompanyValue(data);
            }}
            isOptionDisabled={(option: any) =>
              selectedTab === "reporting" && !option?.allowReport ? true : false
            }
          />

          <TabContainer>
            <Tab
              selected={selectedTab === "reporting"}
              onClick={() => {
                setSelectedTab("reporting");
                setCompany("");
                setCompanyValue({ value: "", label: "" });
              }}
            >
              Reporting
            </Tab>
            <Tab
              selected={selectedTab === "new-number"}
              onClick={() => {
                setSelectedTab("new-number");
                setCompany("");
                setCompanyValue({ value: "", label: "" });
              }}
            >
              New Number
            </Tab>
          </TabContainer>
        </ControlContainer>
      </Header>

      <Div>
        {selectedTab === "reporting" ? (
          <Reporting
            company={company}
            token={token}
            importOptions={importOptions}
          />
        ) : (
          <NewNumber company={company} />
        )}
      </Div>
    </>
  );
}

const ControlContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const TabContainer = styled.div`
  display: flex;

  border: 2px solid #424557;
  border-radius: 8px;

  overflow: hidden;
`;

interface TabProps {
  selected: boolean;
}

const Tab = styled.div<TabProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 130px;
  height: 45px;
  padding: 8px 16px;

  line-height: 1px;
  background-color: ${(props) => (props.selected ? "#3e65ff" : "white")};
  color: ${(props) => (props.selected ? "white" : "black")};
  cursor: pointer;

  transition: background-color 0.15s ease, color 0.15s ease;

  :hover {
    background-color: #3e65ff;
    color: white;
  }
`;

const StyledSelect = styled(Select)`
  width: 500px;
`;

const Div = styled.div`
  min-height: 1000px;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Form = styled.form`
  height: 200px;
  width: 200px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Input = styled.input`
  height: 35px;
  width: 200px;
`;

const Button = styled.button`
  height: 35px;
  width: 200px;
`;

const LogoutButton = styled(Button)``;

const ErrorText = styled.p`
  color: red;
`;

export default App;
